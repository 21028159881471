import { type PropsWithChildren } from "react";

import styles from "./button.module.css";

type ButtonProps = PropsWithChildren<{
  readonly size?: "s" | "m" | "l";
  readonly variant?: "primary" | "secondary" | "tertiary" | "on-surface";
}>;

export const Button = ({
  size = "m",
  variant = "primary",
  children,
}: ButtonProps) => {
  return (
    <button
      className={styles.button}
      type="button"
      data-size={size}
      data-variant={variant}
    >
      {children}
    </button>
  );
};
