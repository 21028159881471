import { URLPattern } from "urlpattern-polyfill";

const BASE_URL = "https://viewbox.fun";

type RouteEntry = {
  readonly pattern: string;
  readonly urlPattern: URLPattern;
};

class RouteRegistry {
  _registry = new Set<RouteEntry>();

  matchRoute(path: string) {
    return (
      Array.from(this._registry).find((entry) =>
        entry.urlPattern.test(`${BASE_URL}${path}`),
      ) ?? null
    );
  }

  getParams(entry: RouteEntry, path: string) {
    return (
      entry?.urlPattern.exec(`${BASE_URL}${path}`)?.pathname.groups ?? null
    );
  }

  addPattern(pattern: string) {
    this._registry.add({
      pattern,
      urlPattern: new URLPattern(pattern, BASE_URL),
    });
  }
}

export const routeRegistry = new RouteRegistry();

export function removePathnameGroups(path: string) {
  return path.replace(/\/:[^/]+/g, "");
}

export function getPlatformRoutingHref(path: string, params: Array<string>) {
  if (path[0] !== "/") {
    throw new Error("Path must start with /");
  }

  if (process.env.BUILD_TYPE !== "desktop") {
    return path;
  }

  const routeEntry = routeRegistry.matchRoute(path);

  if (routeEntry === null) {
    return "";
  }

  const routeParams = routeRegistry.getParams(routeEntry, path);

  const result = new URL(
    `${BASE_URL}${removePathnameGroups(routeEntry.pattern)}`,
  );

  for (const param of params) {
    const routeParamValue = routeParams?.[param] ?? null;

    if (routeParamValue === null) {
      continue;
    }

    result.searchParams.append(param, routeParamValue);
  }

  return result.toString().slice(BASE_URL.length);
}

routeRegistry.addPattern("/movie/:id");
routeRegistry.addPattern("/tv/:id");
routeRegistry.addPattern("/genre/movie/:id");
routeRegistry.addPattern("/genre/tv/:id");
