import { type CSSProperties } from "react";

import styles from "./shimmer.module.css";

type ShimmerProps = {
  readonly width?: CSSProperties["width"];
  readonly height?: CSSProperties["height"];
  readonly className?: string;
  readonly style?: CSSProperties;
};

export const Shimmer = ({ width, height, className, style }: ShimmerProps) => {
  return (
    <div
      className={[styles.root, className].join(" ")}
      aria-live="polite"
      aria-busy="true"
      style={{
        width,
        height,
        ...style,
      }}
    ></div>
  );
};
