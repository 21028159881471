import React, { type PropsWithChildren } from "react";

import styles from "./typography.module.css";

type TypographyProps = PropsWithChildren<{
  readonly className?: string;
  readonly tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  readonly variant?: "heading-3" | "body";
}>;

export const Typography = ({
  className,
  tag,
  variant = "body",
  children,
}: TypographyProps) => {
  const Element = tag;

  return (
    <Element
      className={[className, styles.root].join(" ")}
      data-variant={variant}
    >
      {children}
    </Element>
  );
};
