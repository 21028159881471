"use client";

import {
  AccountCircle as AccountCircleIcon,
  Home as HomeIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import dynamic from "next/dynamic.js";
import { usePathname } from "next/navigation.js";
import { Suspense } from "react";
import { useIntl } from "react-intl";

import { getYandexMetrikaCounterId } from "@/app/get-yandex-metrika-counter-id.js";
import { YandexMetrikaCounter } from "@/widgets/analytics/yandex-metrika-counter.js";
import {
  NavigationRail,
  NavigationRailAction,
} from "@/widgets/navigation-rail/ui/index.js";
import { SnackStack } from "@/widgets/snack-stack/ui/index.js";

const AddToHomeScreen = dynamic(
  () =>
    import("@/features/add-to-home/ui/add-to-home.js").then((m) => ({
      default: m.AddToHomeScreen,
    })),
  {
    ssr: false,
  },
);

export function BaseLayout({ children }: React.PropsWithChildren) {
  const { formatMessage } = useIntl();
  const pathname = usePathname()!;

  const isRootPath = ["/", "/search", "/profile"].includes(pathname);

  return (
    <>
      <Suspense>
        {process.env.NODE_ENV === "production" && (
          <YandexMetrikaCounter id={getYandexMetrikaCounterId()} />
        )}
        <SnackStack />
        {process.env.BUILD_TYPE === "web" && <AddToHomeScreen />}
        <Box
          sx={{
            minHeight: "calc(100vh)",
            padding:
              "0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)",
          }}
        >
          <Box
            sx={{
              visibility: isRootPath ? "visible" : "hidden",
            }}
            component={NavigationRail}
            value={pathname}
          >
            <NavigationRailAction
              label={formatMessage({ id: "main" })}
              icon={<HomeIcon />}
              value={"/"}
            />
            <NavigationRailAction
              label={formatMessage({ id: "search" })}
              icon={<SearchIcon />}
              value={"/search"}
            />
            <NavigationRailAction
              label={formatMessage({ id: "profile" })}
              icon={<AccountCircleIcon />}
              value={"/profile"}
            />
          </Box>
          <Box
            sx={(theme) => ({
              pb: isRootPath ? "72px" : "unset",
              [theme.breakpoints.up("md")]: {
                pl: isRootPath ? "80px" : "unset",
              },
            })}
          >
            {children}
          </Box>
        </Box>
      </Suspense>
      <script type="speculationrules">{`
        {
          "prerender": [
            {
              "urls": ["/search", "/profile"]
            },
            {
              "where": {
                "href_matches": "/*"
              },
              "eagerness": "moderate"
            }
          ]
        }
      `}</script>
    </>
  );
}
