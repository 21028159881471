import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

import { useMemo } from "react";
import { preload } from "react-dom";
import { useIntl } from "react-intl";
import { A11y, Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Button } from "@/shared/design-system/button/button.js";
import { Container } from "@/shared/design-system/container/container.js";
import { Image } from "@/shared/design-system/image/image.js";
import { Link } from "@/shared/design-system/link/link.js";
import { Typography } from "@/shared/design-system/typography/typography.js";
import { getPlatformRoutingHref } from "@/shared/lib/compatibility/route-registry/route-registry.js";

import styles from "./showcase.module.css";

interface FeaturedItem {
  readonly id: number;
  readonly title: string;
  readonly tagline: string;
  readonly tmdbMediaType: "movie" | "tv";
  readonly tmdbBackdropPath: string;
}

// TODO: Translations
const items: FeaturedItem[] = [
  {
    id: 93405,
    title: "Игра в кальмара",
    tagline: "",
    tmdbMediaType: "tv",
    tmdbBackdropPath: "/assets/showcase/squid_game.avif",
  },
  {
    id: 124364,
    title: "Извне",
    tagline: "",
    tmdbMediaType: "tv",
    tmdbBackdropPath: "/assets/showcase/from.avif",
  },
  {
    id: 912649,
    title: "Веном: Последний танец",
    tagline: "Пока смерть не разлучит их",
    tmdbMediaType: "movie",
    tmdbBackdropPath: "/assets/showcase/venom.avif",
  },
  {
    id: 1399,
    title: "Игра престолов",
    tagline: "Зима близко",
    tmdbMediaType: "tv",
    tmdbBackdropPath: "/assets/showcase/game_of_thrones.avif",
  },
  {
    id: 84773,
    title: "Властелин колец: Кольца Власти",
    tagline: "Путешествие к Средиземью",
    tmdbMediaType: "tv",
    tmdbBackdropPath: "/assets/showcase/lord_of_rings.avif",
  },
  {
    id: 1022789,
    title: "Головоломка 2",
    tagline: "Найди место для новых эмоций",
    tmdbMediaType: "movie",
    tmdbBackdropPath: "/assets/showcase/inside_out.avif",
  },
  {
    id: 240411,
    title: "Дандадан",
    tagline: "",
    tmdbMediaType: "tv",
    tmdbBackdropPath: "/assets/showcase/dandadan.avif",
  },
];

interface FeaturedItemProps {
  readonly id: number;
  readonly title: string;
  readonly tagline: string;
  readonly tmdbMediaType: "movie" | "tv";
  readonly tmdbBackdropPath: string;
  readonly eager?: boolean;
}

const ShowcaseItem = ({
  id,
  title,
  tagline,
  tmdbMediaType,
  tmdbBackdropPath,
  eager,
}: FeaturedItemProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className={styles.image}>
        <Image
          src={tmdbBackdropPath}
          eager={eager}
          width="100%"
          height="100%"
          alt={`${formatMessage({ id: "show_backdrop" })}. ${title}`}
          aspectRatio="12 / 5"
          objectFit="cover"
        />
      </div>
      <div className={styles.content}>
        <Typography variant="heading-3" tag="h2">
          {title}
        </Typography>
        <Typography variant="body" tag="p">
          {tagline}
        </Typography>
        <Link
          className={styles.link}
          href={getPlatformRoutingHref(`/${tmdbMediaType}/${id}`, ["id"])}
        >
          <Button size="l" variant="on-surface">
            Смотреть
          </Button>
        </Link>
      </div>
    </>
  );
};

const PreloadResources = (): React.ReactNode => {
  preload(items[0].tmdbBackdropPath, {
    fetchPriority: "high",
    as: "image",
  });

  return;
};

export const Showcase = () => {
  const slider = useMemo(() => {
    return (
      <>
        <Swiper
          speed={500}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          modules={[A11y, Autoplay, EffectFade]}
        >
          {items.map(
            (
              { id, title, tagline, tmdbMediaType, tmdbBackdropPath },
              index,
            ) => (
              <SwiperSlide key={index} className={styles.item}>
                <ShowcaseItem
                  id={id}
                  title={title}
                  tagline={tagline}
                  tmdbMediaType={tmdbMediaType}
                  tmdbBackdropPath={tmdbBackdropPath}
                  eager={index === 0}
                />
              </SwiperSlide>
            ),
          )}
        </Swiper>
      </>
    );
  }, []);

  return (
    <>
      <PreloadResources />
      <Container>{slider}</Container>
    </>
  );
};
