import NextLink from "next/link.js";
import { Link as NextViewTransitionsLink } from "next-view-transitions";
import { type PropsWithChildren } from "react";

type LinkProps = PropsWithChildren<{
  readonly className: string;
  readonly href: string;
  /**
   * @default true
   */
  readonly withViewTransition?: boolean;
}>;

export const Link = ({
  className,
  href,
  withViewTransition = true,
  children,
}: LinkProps) => {
  const Component = withViewTransition
    ? NextViewTransitionsLink
    : NextLink.default;

  return (
    <Component className={className} href={href}>
      {children}
    </Component>
  );
};
